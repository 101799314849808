<!--
 * @Author: your name
 * @Date: 2021-08-01 13:21:04
 * @LastEditTime: 2021-09-27 17:35:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\user\userCenter\index.vue 
-->
<template>
  <div class="userCenter flex-h flex-vc">
    <aside>
      <img class="head" :src="avatar" />
      <div class="edit" @click="dialogFormVisible = true">
        <span>修改</span>
        <img src="../../../assets/img/edit.png" />
      </div>
      <div class="desc">{{ ruleForm.declaration }}</div>
      <ul>
        <li>
          <span>昵称</span><span>{{ ruleForm.nickname }}</span>
        </li>
        <li>
          <span>姓名</span><span>{{ ruleForm.userName }}</span>
        </li>
        <li>
          <span>生日</span
          ><span>{{ $formatDate(ruleForm.birthday, "{y}-{m}-{d}") }}</span>
        </li>
        <li>
          <span>性别</span><span v-if="ruleForm.sex == 1">男</span
          ><span v-if="ruleForm.sex == 2">女</span>
        </li>
        <li>
          <span>证件类型</span>
          <span v-if="ruleForm.idType == 1">居民身份证</span>
          <span v-if="ruleForm.idType == 2">香港居民身份证</span>
          <span v-if="ruleForm.idType == 3">澳门居民身份证</span>
          <span v-if="ruleForm.idType == 4">台湾身份证</span>
          <span v-if="ruleForm.idType == 5">护照</span>
        </li>
        <li>
          <span>证件号码</span><span>{{ ruleForm.idNumber }}</span>
        </li>
        <li class="flex-vc">
          <span>实名认证</span>
          <div class="tag" v-if="affirm">已实名认证</div>
          <div class="tag" v-else>未实名认证</div>
          <a
            v-if="affirm == 2 || affirm == 3"
            @click="$router.push({ name: 'auth', query: { UT: affirm } })"
            >修改</a
          >
          <a v-if="affirm == 1" @click="$router.push({ name: 'cert' })">修改</a>
        </li>
        <li>
          <span>志愿者编号</span><span>{{ ruleForm.volunteerNo }}</span>
        </li>
        <li>
          <span>注册时间</span
          ><span>{{ $formatDate(ruleForm.insertTime) }}</span>
        </li>
        <li>
          <span>手机号码</span><span>{{ ruleForm.phone }}</span>
        </li>
        <li>
          <span>电话号码</span><span>{{ ruleForm.telephone }}</span>
        </li>
        <li>
          <span>现居地址</span
          ><span style="height: 35px; overflow-y: auto"
            >{{ ruleForm.liveAddress.province }}{{ ruleForm.liveAddress.city
            }}{{ ruleForm.liveAddress.area }}</span
          >
        </li>
        <li>
          <span>详细地址</span
          ><span style="height: 35px; overflow-y: auto">{{
            ruleForm.liveAddress.address
          }}</span>
        </li>
      </ul>
    </aside>
    <div class="content">
      <div class="Affirm" v-if="affirm">
        <div class="Affirm-header flex-h flex-vc">
          <h1>尊敬的志愿者，您好！您当前的身份：</h1>
          <span v-if="affirm == 3">会员志愿者</span>
          <span v-if="affirm == 2">学生志愿者</span>
          <span v-if="affirm == 1">注册志愿者</span>
          <div class="teamLeader" v-if="teamLeader == 1">队长</div>
          <div v-if="reviewType === 0" class="audit">待审核</div>
          <div
            class="tagStatus"
            @click="$router.push({ name: 'auth' })"
            v-else-if="affirm == 1"
          >
            <a v-if="affirm == 1">身份更换</a>
            <a v-if="affirm == 0">身份认证</a>
          </div>
        </div>
        <div class="Affirm-box">
          <div class="flex-h">
            <div class="flex-1">
              <p class="text1">
                {{ joinActivityNum == null ? 0 : joinActivityNum }}
              </p>
              <p class="text2">参加活动</p>
            </div>
            <div class="flex-1">
              <p class="text1">{{ longTime == null ? 0 : longTime }}小时</p>
              <p class="text2">累计服务时长</p>
            </div>
          </div>
          <div class="flex-h flex-hc">
            <dl class="flex-1 flex-h flex-hc">
              <div class="certificate1">
                <p v-if="affirm == 1">注册志愿者</p>
                <p v-if="affirm == 2">学生志愿者</p>
                <p v-if="affirm == 3">会员志愿者</p>
                <p>{{ ruleForm.userName }}</p>
                <p>{{ ruleForm.volunteerNo }}</p>
                <img
                  :src="ruleForm.visualHeadImg"
                  style="
                    border-style: solid;
                    border-width: 2px;
                    border-color: white;
                  "
                />
              </div>
            </dl>
            <dl class="flex-1 flex-v flex-vc" v-if="affirm == 2 || affirm == 3">
              <img
                v-if="affirm == 2"
                class="certificate2"
                src="@/assets/img/zs1.png"
              />

              <img
                v-if="affirm == 1 || affirm == 3"
                class="certificate2"
                src="@/assets/img/zs2.png"
              />
              <div class="btn" @click="$router.push({ name: 'apply' })">
                申请纸质证书
              </div>
            </dl>
          </div>
        </div>
        <div class="title">志愿者须知</div>
        <div class="Affirm-desc">
          1 .
          志愿者电子证是由中华志愿者协会少儿艺术公益项目管理委员会提供的志愿者身份识别电子产品。<br />
          2 . 所有者须为中华志愿者协会少儿艺术公益项目管理委员会平台用户<br />
          3 .
          志愿者电子证只对中华志愿者协会少儿艺术公益项目管理委员会平台志愿者身份认证提供帮助。<br />
          4 .
          请妥善保管，使用时请遵守相关规定和章程，如有违法违规等行为，中华志愿者协会少儿艺术公益项目管理委员会不承担法律后果。<br />
          5 .
          本志愿者电子证最终解释权归中华志愿者协会少儿艺术公益项目管理委员会所有。
        </div>
      </div>
      <div class="noAffirm" v-else>
        <div class="noAffirm-header flex-h flex-vc flex-hc">
          <h1>您当前身份：</h1>
          <div class="isNo">未认证</div>
          <p>
            我们只为身份认证成功的用户记录
            <br />
            服务时长，请及时认证！
          </p>
          <div
            class="goAffirm"
            @click="$router.push({ name: 'auth', query: { status: 2 } })"
          >
            身份认证
          </div>
        </div>
        <div class="tags flex-h flex-hc">
          <div class="tag">
            <img src="@/assets/img/team1.png" />
            <p>0个</p>
            <p>加入组织</p>
          </div>
          <div class="tag">
            <img src="@/assets/img/team2.png" />
            <p>0次</p>
            <p>参加志愿活动</p>
          </div>
          <div class="tag">
            <img src="@/assets/img/team3.png" />
            <p>0小时</p>
            <p>累计服务时长</p>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="修改个人信息"
      :visible.sync="dialogFormVisible"
      center
      width="700px"
      :modal-append-to-body="false"
      :append-to-body="true"
      @close="close"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        style="width: 600px; margin-top: 50px"
        size="small"
      >
        <el-form-item label="昵称" prop="nickname">
          <el-input
            v-model="ruleForm.nickname"
            maxlength="20"
            show-word-limit
            clearable
            placeholder="昵称"
          ></el-input>
        </el-form-item>
        <el-form-item label="生日" prop="birthday">
          <el-date-picker
            v-model="ruleForm.birthday"
            type="date"
            value-format="timestamp"
            placeholder="选择日期时间"
            :picker-options="expireTimeOption"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="性别">
          <el-radio-group v-model="ruleForm.sex">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="头像" prop="imageUrl">
          <el-upload
            class="avatar-uploader"
            :action="`${$baseURL}/file/publicUpload`"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="ruleForm.imageUrl"
              :src="ruleForm.imageUrl"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">尺寸要求：200*200</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="公益宣言" prop="declaration">
          <el-input
            v-model="ruleForm.declaration"
            maxlength="30"
            show-word-limit
            clearable
            placeholder="请输入公益宣言"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="telephone">
          <el-input
            v-model="ruleForm.telephone"
            maxlength="20"
            show-word-limit
            clearable
            placeholder="请输入电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="现居地址" prop="location">
          <el-cascader
            :options="mapOptions"
            v-model="ruleForm.location"
            :props="{ value: 'label' }"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input
            v-model="ruleForm.address"
            maxlength="100"
            show-word-limit
            clearable
            placeholder="请输入详细地址"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 审核通过通知 -->
    <transition name="fade">
      <div class="poput" v-if="showSuccess">
        <div class="box flex-v flex-hc flex-vc">
          <img src="@/assets/img/success.png" />
          <p>恭喜您成为会员志愿者</p>
          <div class="button" @click="closeMsg">关 闭</div>
        </div>
      </div>
    </transition>
    <!-- 审核拒绝通知 -->
    <transition name="fade">
      <div class="errorMessage flex-h flex-vc" v-if="showError">
        <div class="msg">
          您申请成为会员志愿者已被拒绝。拒绝原因：{{ refuseReason }}
        </div>
        <img @click="closeMsg" src="@/assets/img/close-circle@2x.png" />
      </div>
    </transition>
  </div>
</template>
<script>
import { getToken, getUserInfo, setUserInfo } from "@/utils/auth";
import options from "@/utils/country-level3-data";
import {
  getUser,
  updateUser,
  volunteer,
  applyNotice,
  closeNotice,
} from "@/api/index";

export default {
  data() {
    return {
      dialogFormVisible: false,
      headers: {
        token: getToken(),
      },
      reviewType: "",
      teamLeader: "",
      joinActivityNum: "",
      longTime: "",
      expireTimeOption: {
        disabledDate(date) {
          //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return date.getTime() > Date.now();
        },
      },
      ruleForm: {
        declaration: "",
        nickname: "",
        imageUrl: "",
        sex: 0,
        birthday: "",
        telephone: "",
        location: ["", "", ""],
        address: "",
        liveAddress: {},
        nickname: "",
      },
      mapOptions: options,
      rules: {
        nickname: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        imageUrl: [{ required: true, message: "请上传头像", trigger: "blur" }],
        declaration: [
          { required: true, message: "请输入宣言", trigger: "blur" },
        ],
        birthday: [{ required: true, message: "请选择时间", trigger: "blur" }],
        telephone: [
          { required: true, message: "请输入电话信息", trigger: "blur" },
        ],
        location: [
          { required: true, message: "请输入地址", trigger: "change" },
        ],
        address: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
      },
      showSuccess: false,
      showError: false,
      refuseReason: "",
      noticeId: "",
    };
  },
  methods: {
    volunteer() {
      volunteer().then((res) => {
        this.joinActivityNum = res.data.joinActivityNum;
        this.longTime = res.data.longTime;
      });
    },
    getNotice() {
      applyNotice().then((res) => {
        this.noticeId = res.data.id;
        if (res.data.reviewType == 1) {
          this.showSuccess = true;
        }
        if (res.data.reviewType == 2) {
          this.showError = true;
          this.refuseReason = res.data.refuseReason;
        }
      });
    },
    getInfo() {
      getUser().then((res) => {
        let data = res.data;
        this.teamLeader = res.data.teamLeader;
        this.reviewType = res.data.reviewType;
        let liveAddress = data.liveAddress
          ? data.liveAddress
          : {
              province: "",
              city: "",
              area: "",
              address: "",
            };
        this.ruleForm.liveAddress = liveAddress;
        this.ruleForm.location = [
          liveAddress.province,
          liveAddress.city,
          liveAddress.area,
        ];
        this.ruleForm.birthday = data.birthday * 1000 || "";
        this.ruleForm.address = liveAddress.address;
        this.ruleForm.declaration = data.declaration;
        this.ruleForm.imageUrl = data.imageUrl;
        this.ruleForm.idNumber = data.idNumber;
        this.ruleForm.nickname = data.nickname;
        this.ruleForm.phone = data.phone;
        this.ruleForm.race = data.race;
        this.ruleForm.sex = data.sex;
        this.ruleForm.insertTime = data.insertTime;
        this.ruleForm.idType = data.idType;
        this.ruleForm.volunteerNo = data.volunteerNo;
        this.ruleForm.userName = data.userName;
        this.ruleForm.telephone = data.telephone;
        this.ruleForm.visualHeadImg = data.visualHeadImg;
        let userInfo = getUserInfo();
        userInfo.nickname = this.ruleForm.nickname;
        userInfo.imageUrl = this.ruleForm.imageUrl;
        setUserInfo(userInfo);
        this.$store.commit("SET_AFFIRM", data.userType);
        this.$store.commit("SET_AVATAR", this.ruleForm.imageUrl);
      });
    },
    closeMsg() {
      this.showError = false;
      this.showSuccess = false;
      closeNotice(this.noticeId);
    },
    close() {
      this.getInfo();
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            address: this.ruleForm.address,
            area: this.ruleForm.location[2],
            birthday: this.ruleForm.birthday / 1000,
            city: this.ruleForm.location[1],
            declaration: this.ruleForm.declaration,
            imageUrl: this.ruleForm.imageUrl,
            nickname: this.ruleForm.nickname,
            province: this.ruleForm.location[0],
            sex: this.ruleForm.sex,
            telephone: this.ruleForm.telephone,
          };
          updateUser(data).then((res) => {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.dialogFormVisible = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.ruleForm.imageUrl = res.data;
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 < 1024*3;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG/PNG 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 3M!");
        return false;
      }
      return true;
    },
  },
  computed: {
    avatar() {
      return this.$store.state.avatar;
    },
    affirm() {
      return this.$store.state.affirm;
    },
  },
  created() {
    this.getInfo();
    this.volunteer();
    this.getNotice();
  },
};
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
li {
  list-style-type: none;
}
.userCenter {
  height: 100%;
  aside {
    width: 240px;
    height: 100%;
    box-sizing: border-box;
    padding-top: 25px;
    .head {
      width: 63px;
      height: 63px;
      display: block;
      margin-left: 75px;
      border-radius: 50%;
    }
    .edit {
      cursor: pointer;
      text-align: right;
      padding-right: 40px;
      margin-bottom: 3px;
      span {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #ff6e6e;
      }
      img {
        width: 16px;
        margin-left: 5px;
      }
    }
    .desc {
      width: 214px;
      height: 70px;
      margin: 0 auto;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      line-height: 17px;
      background: url("../../../assets/img/jx.png") no-repeat center center /
        100% 100%;
      box-sizing: border-box;
      padding: 15px 17px;
      margin-bottom: 30px;
      word-break: break-all;
    }
    ul {
      li {
        padding-left: 16px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #696969;
        margin-bottom: 16px;
        display: flex;
        flex-direction: row;

        span {
          display: inline-block;
          &:nth-of-type(1) {
            width: 80px;
          }
          &:nth-of-type(2) {
            width: 140px;
          }
        }
        .tag {
          width: 92px;
          height: 23px;
          background: #ff6e6e;
          border-radius: 16px;
          text-align: center;
          line-height: 23px;
          color: #ffffff;
        }
        a {
          margin-left: 16px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: #ff6e6e;
          cursor: pointer;
        }
      }
    }
  }
  .content {
    width: 599px;
    height: 642px;
    background: #fff6f6;
    border-radius: 23px;
    .Affirm {
      position: relative;
      padding: 16px 20px 0 20px;
      .Affirm-header {
        h1 {
          font-size: 16px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #000000;
        }
        span {
          font-size: 16px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: rgba(255, 110, 110, 1);
        }
        .teamLeader {
          width: 52px;
          height: 23px;
          border-radius: 13px;
          border: 1px solid #ff6e6e;
          text-align: center;
          margin-left: 20px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #ff6e6e;
          line-height: 23px;
        }
        .icon {
          width: 16px;
          margin-left: 5px;
          cursor: pointer;
        }
        .tagStatus {
          width: 80px;
          height: 25px;
          background: #ff6e6e;
          border-radius: 6px;
          cursor: pointer;
          text-align: center;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          line-height: 25px;
          margin-left: 120px;
        }
        .audit {
          width: 80px;
          height: 25px;
          background: #bfbdbd;
          border-radius: 16px;

          text-align: center;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          line-height: 25px;
          margin-left: 50px;
        }
      }
      .Affirm-box {
        margin-top: 40px;
        .text1 {
          font-size: 24px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #636363;
          text-align: center;
        }
        .text2 {
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #b3b3b3;
          text-align: center;
          margin-top: 5px;
          margin-bottom: 20px;
        }
        dl {
          .certificate1 {
            width: 266px;
            height: 171px;
            background: url("../../../assets/img/zjbg.png") no-repeat center
              center/100% 100%;
            position: relative;
            margin-bottom: 20px;
            img {
              width: 54px;
              height: 66px;

              position: absolute;
              top: 21px;
              right: 22px;
            }
            p {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #000000;
              position: absolute;
              left: 14px;
              &:nth-of-type(1) {
                top: 12px;
                left: 48px;
              }
              &:nth-of-type(2) {
                top: 65px;
              }
              &:nth-of-type(3) {
                top: 103px;
              }
            }
          }
          .certificate2 {
            width: 118px;
            height: 167px;
          }
          .btn {
            width: 125px;
            height: 28px;
            border-radius: 16px;
            border: 1px solid #ff6e6e;
            text-align: center;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #ff6e6e;
            line-height: 28px;
            margin-top: 22px;
            cursor: pointer;
          }
        }
      }
      .title {
        font-size: 16px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #ff6e6e;
        margin-top: 4px;
      }
      .Affirm-desc {
        margin-top: 15px;
        height: 199px;
        background: #ffffff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 26px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #636363;
        line-height: 22px;
      }
    }
    .noAffirm {
      .noAffirm-header {
        padding-top: 25px;
        h1 {
          font-size: 16px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #000000;
        }
        .isNo {
          width: 72px;
          height: 29px;
          background: #b0b0b0;
          border-radius: 16px;
          text-align: center;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          line-height: 29px;
          margin: 0 25px;
        }
        p {
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: #403f3f;
          line-height: 20px;
        }
        .goAffirm {
          width: 99px;
          height: 32px;
          background: #ff6e6e;
          border-radius: 16px;
          text-align: center;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          line-height: 32px;
          margin-left: 40px;
          cursor: pointer;
        }
      }
      .tags {
        margin-top: 70px;
        .tag {
          margin: 0 50px;
          img {
            width: 60px;
            display: block;
            margin: 0 auto;
          }
          p {
            text-align: center;

            &:nth-of-type(1) {
              font-size: 18px;
              font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
              font-weight: bold;
              color: #242424;
              margin-top: 5px;
              margin-bottom: 2px;
            }
            &:nth-of-type(2) {
              font-size: 16px;
              font-family: MicrosoftYaHei;
              color: #242424;
            }
          }
        }
      }
    }
  }
}
.errorMessage {
  position: absolute;
  top: -15px;
  left: 350px;
  .msg {
    white-space: nowrap;
    height: 26px;
    background: #fff;
    border: 1px solid #ff6e6e;
    border-radius: 20px;
    padding: 0 10px;
    text-align: center;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #ff6e6e;
    line-height: 26px;
  }
  img {
    padding: 5px;
    cursor: pointer;
    width: 20px;
  }
}
.poput {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
  .box {
    width: 300px;
    height: 203px;
    background: #ffffff;
    border-radius: 8px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 50px;
    }
    p {
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #696969;
      line-height: 27px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
  .button {
    width: 122px;
    height: 37px;
    background: #ff6e6e;
    border-radius: 24px;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
    line-height: 37px;
  }
}
</style>